import React from "react";

/**
 *
 * @param {boolean} white
 * @returns {JSX.Element}
 * @constructor
 */
const PostcodeApiVideo = ({white = false}) => (
  <video width="100%" height="100%" autoPlay loop muted>
    <source src={`/assets/postcodeapi${white ? '-white' : ''}.mp4`} type="video/mp4"/>
    Your browser does not support the video tag.
  </video>
)

export default PostcodeApiVideo;
