
import React, {useEffect, useState} from "react";
import BodyContainer from "../../components/BodyContainer";
import {useLocation} from "react-router-dom";
import Post from "../../pages/Post";
import PropTypes from "prop-types";
import {ReactSVG} from "react-svg";
import CheckedSvg from "../../assets/icon/checked.svg";
import {PostcodeCheckout} from "../../services/postcodeCheckout";
import PostcodeApiVideo from "../PostcodeApiVideo";

const PluginPage = (
  {
    name,
    version,
    description,
    isWebShop = true,
    link
  }
) => {
  const location = useLocation();
  const [plugin, setPlugin] = useState(undefined);

  useEffect(() => {
    (async () => {
      const res =  await PostcodeCheckout.getPlugin(location.pathname.replace(/\//g, ''));
      setPlugin(res);
    })()
  }, [location]);

  if (!plugin) {
    return null;
  }
  return (
    <Post head={{
      title: `Adresvalidatie in ${name}`,
      description: `Integreer Postcode API eenvoudig in ${name} om automatisch adressen te valideren.`,
    }}>
      <BodyContainer>
        <div className="sdk-img">
          <img alt={`Postcode API plugin voor ${name}`} src={plugin.image}/>
        </div>
        <h1>Adresvalidatie in {name}</h1>
        <ul className="checklist checklist-blue">
          <li><ReactSVG src={CheckedSvg} className="checklist-icon"/>Altijd de juiste adresgegevens van je klanten</li>
          <li><ReactSVG src={CheckedSvg} className="checklist-icon"/>Extra gebruiksgemak bij het invullen van formulieren</li>
          <li><ReactSVG src={CheckedSvg} className="checklist-icon"/>100% uptime en snelle responses gegarandeerd</li>
          <li><ReactSVG src={CheckedSvg} className="checklist-icon"/>Eenvoudig te implementeren in {name}</li>
        </ul>
        <PostcodeApiVideo white/>

        <h2 id={`postcode-api-plugin-voor-${name}`}>Postcode API plugin voor {name}</h2>
        <p>
          Door Postcode API in jouw {name} {isWebShop ? "webshop" : 'website' } te implementeren worden adresgegevens direct gevalideerd. Zo ben jij altijd verzekerd van de juiste adresgegevens en ervaart je gebruiker meer gemak bij het invullen van formulieren.
        </p>

        <p>Postcode API werkt samen met Postcode Checkout voor de ontwikkeling van een plugin voor {name} {version}. Postcode API levert de data, Postcode Checkout is verantwoordelijk voor het beheer, de doorontwikkeling en support bij installatie van de plugin.</p>

        <table className="table table-plugin">
          <tbody>
          <tr>
            <th>Platform</th>
            <th>Omschrijving</th>
            <th>Versie</th>
            <th>Link</th>
          </tr>
          <tr>
            <td>
              <a href={link} target="_blank" rel="nofollow noreferrer noopener">
                <img alt={`Postcode API plugin voor ${name}`} src={plugin.image}/>
              </a>
            </td>
            <td>
              {description}
            </td>
            <td>{version}</td>
            <td>
              <a href={link} target="_blank" rel="nofollow noreferrer noopener">Naar Postcode Checkout</a>
            </td>
          </tr>
          </tbody>
        </table>

        <div className="align-center">
          <a href={link} target="_blank" rel="nofollow noreferrer noopener" className="button buttonTertiary">Bestel de plugin bij Postcode Checkout</a>
          <p>
            <em>Let op: om bovenstaande plugin te gebruiken heb je naast je abonnement bij Postcode API ook een abonnement nodig bij Postcode Checkout.</em>
          </p>
        </div>
      </BodyContainer>
    </Post>
  )
}

PluginPage.props = {
  name: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}

export default PluginPage;
