import React from 'react';
import PropTypes from 'prop-types';
import BodyContainer from '../../components/BodyContainer';
import Page from '../Page';
import classnames from 'classnames';

const Post = ({
  head,
  htmlString,
  children,
  className,
  childrenOutSideContainer,
  __url,
}) => {
  return (
    <Page childrenOutSideContainer={childrenOutSideContainer} head={ head } alignment="left" __url={ __url } className={classnames('post', className)}>
      { htmlString && (
        <BodyContainer>
          <div dangerouslySetInnerHTML={{
            __html: htmlString
          }} />
        </BodyContainer>
      )}
      {children}
    </Page>
  )
};

Post.propTypes = {
  head: PropTypes.object.isRequired,
  htmlString: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  childrenOutSideContainer: PropTypes.node,
  __url: PropTypes.string,
};

export default Post;
