import React, {useEffect, useRef, useState} from "react";
import PropTypes from 'prop-types';
import classNameFactory from "classnames";

import BgImage from '../../assets/bg.svg';

const Section = ({ children,showBg, className, sectionBrand, sectionLarge, sectionBrandInvert, sectionSecondary, sectionCenter, sectionCustomers, sectionFlatten, sectionSkewLeft, sectionSkewRight, ...otherProps}) => {
  const sectionRef = useRef();
  const [renderBg, setRenderBg] = useState(false);
  const classNames = classNameFactory(className, {
    "section": true,
    "sectionBrand": sectionBrand,
    "sectionBrandInvert": sectionBrandInvert,
    "sectionBgWrapper": showBg && (sectionBrand || sectionBrandInvert),
    "sectionLarge": sectionLarge,
    "sectionSecondary": sectionSecondary,
    "sectionCenter": sectionCenter,
    "sectionCustomers": sectionCustomers,
    "sectionFlatten": sectionFlatten,
    "sectionSkew": sectionSkewLeft || sectionSkewRight,
    "sectionSkewLeft": sectionSkewLeft,
    "sectionSkewRight": sectionSkewRight
  });

  useEffect(() => {
    if (!(showBg && (sectionBrand || sectionBrandInvert))) {
      return;
    }
    const scroll = () => {
      if (renderBg || !sectionRef.current) return;

      const offsetTop = sectionRef.current.offsetTop;
      const offsetBottom = sectionRef.current.offsetHeight + offsetTop;
      const windowScrollTop = window.scrollY;
      if (offsetTop <= windowScrollTop && offsetBottom > windowScrollTop) {
        setRenderBg(true);
      }
    }

    window.addEventListener('scroll', scroll);
    scroll();
    return () => {
      window.removeEventListener('scroll', scroll);
    }
  }, [showBg, renderBg, sectionBrand, sectionBrandInvert]);

  return (
    <section ref={sectionRef} className={ classNames } { ...otherProps }>
      { renderBg && <div className="sectionBg" aria-hidden style={{
        backgroundImage: `url(${BgImage})`
      }}/>}
      <div className="sectionContent">
        { children }
      </div>
    </section>
  );
}

Section.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  sectionBrand: PropTypes.bool,
  sectionBrandInvert: PropTypes.bool,
  sectionLarge: PropTypes.bool,
  sectionSecondary: PropTypes.bool,
  sectionCenter: PropTypes.bool,
  sectionCustomers: PropTypes.bool,
  sectionFlatten: PropTypes.bool,
  sectionSkewLeft: PropTypes.bool,
  sectionSkewRight: PropTypes.bool,
  showBg: PropTypes.bool
};

Section.defaultProps = {
  showBg: true
};

export default Section;
