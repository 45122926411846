import React, {useMemo} from "react";
import BodyContainer from "../../components/BodyContainer";
import {Link} from "react-router-dom";
import Post from "../../pages/Post";
import PropTypes from "prop-types";
import SDKS from "../../global/sdks";
import SDKTable from "../SDKTable";
import cx from "classnames";
import Preview from '../Preview';
import {ReactSVG} from "react-svg";
import CheckedSvg from "../../assets/icon/checked.svg";
import PostcodeApiVideo from "../PostcodeApiVideo";

const SDKPage = (
  {
    name,
    sdkNames,
  }
) => {
  const by = useMemo(() => {
    const sdk = SDKS.find((sdk => sdk.spec === name));
    if (!sdk) {
      return null;
    }

    return sdk.by;
  }, [name]);
  const sdks = useMemo(() => {
    return SDKS.filter((sdk => sdkNames.includes(sdk.spec)));
  }, [sdkNames]);
  return (
    <Post head={{
      title: `Adresvalidatie in jouw ${name} applicatie`,
      description: `Integreer Postcode API eenvoudig in jouw ${name} applicatie om automatisch adressen te valideren.`,
    }}
    childrenOutSideContainer={<div className="sdk-preview">
      <Preview code={name}/>
      <div className="align-center">
        <Link to="/gratis-proberen" className={cx("button" , "buttonCta" )}>Gratis proberen</Link>
      </div>
      </div>
    }>
      <BodyContainer>
        <div className="sdk-img">
          <img alt={`Eenvoudig te implementeren in ${name}`} src={sdks[0].logo}/>
        </div>
        <h1>Adresvalidatie in jouw {name} applicatie</h1>
        <ul className="checklist checklist-blue">
          <li><ReactSVG src={CheckedSvg} className="checklist-icon"/>Altijd de juiste adresgegevens van je klanten</li>
          <li><ReactSVG src={CheckedSvg} className="checklist-icon"/>Extra gebruiksgemak bij het invullen van formulieren</li>
          <li><ReactSVG src={CheckedSvg} className="checklist-icon"/>100% uptime en snelle responses gegarandeerd</li>
          <li><ReactSVG src={CheckedSvg} className="checklist-icon"/>Eenvoudig te implementeren in {name}</li>
        </ul>
        <PostcodeApiVideo white/>

        <h2 id={`eenvoudig-te-implementeren-in-${name}`}>Eenvoudig te implementeren in {name}</h2>
        <p>
          Door Postcode API in jouw {name} applicatie te implementeren worden adresgegevens direct gevalideerd. Zo ben jij altijd verzekerd van de juiste adresgegevens en ervaart je gebruiker meer gemak bij het invullen van formulieren. Voor {name} is er een SDK ontwikkeld door {by}.
        </p>

        <SDKTable sdks={sdks} hideLinks/>
      </BodyContainer>
    </Post>
  )
}

SDKPage.props = {
  name: PropTypes.string.isRequired,
  sdkNames: PropTypes.arrayOf(PropTypes.string)
}

export default SDKPage;
