import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import moment from 'moment';
import Container from '../../components/Container';
import Section from '../../components/Section';

moment.locale('nl');

const Page = ({
  children,
  head,
  heading,
  className,
  childrenOutSideContainer,
  __url,
  alignment,
}) => {
  const headerStyle = {};

  if (head.headerImage) {
    headerStyle.backgroundImage = 'url(' + head.headerImage + ')';
  }

  const title = (head.metaTitle ? head.metaTitle : head.title);

  alignment = alignment ? alignment : 'left';

  const link = [
    { rel: "canonical", href: "https://www.postcodeapi.nu" + __url },
  ];

  return (
    <div className={className}>
      <Helmet
        title={ title + ' | Postcode API' }
        meta={[
          { name: "description", content: head.description },
          { property: "og:type", content: "article" },
          { property: "og:title", content: title },
          { property: "og:description", content: head.description },
          { property: "og:site_name", content: "Postcode API" },
          { property: "og:url", content: "https://www.postcodeapi.nu" + __url },
          { property: "og:image", content: "https://www.postcodeapi.nu" + (head.headerImage ? head.headerImage : "/assets/logo/share-logo.png") },
          { property: "fb:app_id", content: "254153114996381" },
        ]}
        link={ link }
      />
      <Section sectionSkewRight>
        <Container className="container" alignment={ alignment }>
          <div className="pageContent">
            { heading && <h1 className="pageHeading">{ heading }</h1> }
            { children }
          </div>
        { childrenOutSideContainer }
        </Container>
      </Section>
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.node,
  childrenOutSideContainer: PropTypes.node,
  head: PropTypes.object.isRequired,
  heading: PropTypes.string,
  className: PropTypes.string,
  __url: PropTypes.string,
  hideTitle: PropTypes.bool,
  alignment: PropTypes.string,
};

export default Page;
