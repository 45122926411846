import Php from './php.png';
import CNET from './cnet.png';
import Ruby from './ruby.png';
import Python from './python.png';
import Symfony from './symfony.png';
import NodeJS from './nodejs.png';

const SDKS = [{
  logo: Php,
  spec: 'PHP',
  by: 'Freshheads',
  internalLink: '/php-postcodeapi',
  link: 'https://github.com/freshheads/FHPostcodeAPIClient'
}, {
  logo: Symfony,
  spec: 'Symfony',
  by: 'Shivella',
  internalLink: '/symfony-postcodeapi',
  link: 'https://github.com/Shivella/postcode-bundle'
},{
  logo: CNET,
  spec: '.NET',
  by: 'Barry Postma',
  internalLink: '/cnet-postcodeapi',
  link: 'https://github.com/BAPostma/PostcodeAPI.Net'
}, {
  logo: Python,
  spec: 'Python',
  by: 'Ruud Schroën',
  internalLink: '/python-postcodeapi',
  link: 'https://github.com/roedesh/postcodeapi'
},{
  logo: Ruby,
  spec: 'Ruby',
  by: 'Ariejan de Vroom',
  internalLink: '/ruby-postcodeapi',
  link: 'https://github.com/ariejan/postcodeapi'
}, {
  logo: NodeJS,
  spec: 'NodeJs',
  by: 'Joost de Bruijn',
  internalLink: '/nodejs-postcodeapi',
  link: 'https://github.com/joostdebruijn/node-postcode-nl'
}]

export default SDKS
