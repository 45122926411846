import React from "react"

const Loading = () => (
  <div>
    <div className="loader">
      <div className="spinner" />
    </div>
  </div>
)

export default Loading
