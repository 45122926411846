import React from 'react';
import Widget from '../Widget';
import {Link} from "react-router-dom";

class Preview extends React.Component {
    static defaultProps = {
        code: 'eigen'
    }
    constructor(props, context) {
        super(props, context);

        this.state = {
            console: 'Vul hiernaast een postcode en huisnummer in en zie realtime wat er onder de motorkap gebeurt. In dit voorbeeld wordt de API getriggerd door van veld te wisselen, uiteraard kun je dit volledig aanpassen voor je eigen formulieren.',
            hasError: false
        };
    }

    handleWidgetError = () => {
        this.setState({
            console: `${this.state.console}\n\n404 Not found (${Date.now() - this.state.start} ms):\n{\n  "error": "Adres niet gevonden"\n}`,
            hasError: true
        })
    };

    handleWidgetSuccess = result => {
        this.setState({
            console: `${this.state.console}\n\n200 OK (${Date.now() - this.state.start} ms):\n{\n  "street": ${JSON.stringify(result.street)},\n  "city": ${JSON.stringify(result.city)}\n}`,
            hasError: false
        })
    };

    handleWidgetLoading = () => {
        this.setState({
            console: 'GET https://api.postcodeapi.nu/v3/lookup',
            start: Date.now(),
            hasError: false
        })
    };

    render() {
        return (
          <div className="previewWrapper">

              <h2 id="preview">Test de API</h2>
              <p className="sectionDescription">
                  Benieuwd hoe Postcode API in de praktijk werkt? Vul hieronder een postcode en huisnummer in en zie realtime wat er onder de motorkap gebeurt.
                  Testen in je {this.props.code} code? Registreer dan een API key waarmee je de API <Link to="/gratis-proberen" className="introLink" >gratis kunt proberen</Link>.
              </p>
              <div className="preview">
                  <div className="previewColumn">
                      <div className="previewFormWrapper">
                          <form className="previewForm">
                              <Widget onError={ this.handleWidgetError } onLoading={ this.handleWidgetLoading } onSuccess={ this.handleWidgetSuccess }>
                                  <div className="formGroup">
                                      <label>Postcode &amp; huisnummer</label>
                                      <div className="FormInlineGroup">
                                          <div className="FormInput">
                                              <input id="postcode" type="text" placeholder="6545 CA"/>
                                          </div>
                                          <div className="FormInput">
                                              <input id="number" type="text" placeholder="29"/>
                                          </div>
                                          <div className="FormInput">
                                              <input id="addition" type="text" placeholder="U31"/>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="formGroup">
                                      <label htmlFor="street">Straatnaam</label>
                                      <div className={`FormInput ${this.state.hasError ? '-hasError' : ''}`}>
                                          <input id="street" type="text" placeholder="Binderskampweg"/>
                                      </div>
                                  </div>
                                  <div className="formGroup">
                                      <label htmlFor="city">Woonplaats</label>
                                      <div className={`FormInput ${this.state.hasError ? '-hasError' : ''}`}>
                                          <input id="city" type="text" placeholder="Nijmegen"/>
                                      </div>
                                  </div>
                              </Widget>
                          </form>
                      </div>
                  </div>
                  <div className="previewColumn">
                        <pre className="previewCode">
                            { this.state.console }
                        </pre>
                  </div>
              </div>
          </div>
        );
    }
}

export default Preview;
