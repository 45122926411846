import React from "react";
import PropTypes from 'prop-types';
import classNameFactory from 'classnames';

class Content extends React.Component {
  render() {
    const contentClassName = classNameFactory({
      "content": true
    });

    return (
      <div className={ contentClassName }>
        { this.props.children }
      </div>
    );
  }
}



Content.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object.isRequired
};

export default Content;
