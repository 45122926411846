import React from 'react';
import { Link } from 'react-router-dom';
import Container from '../Container';
import cx from 'classnames';

import PostcodeAPIBlue from '../../assets/postcode-api-blue.svg';
import AWSImage from '../../assets/aws.png';
import AWSx2Image from '../../assets/aws@2.png';
import LazyloadImage from "../LazyloadImage";

const Footer = (props) => (
  <footer className="footer">
    <div className="footerContent">
      <Container>
        <div className="footerWrapper">
          <div className={ cx("footerColumn","footerColumnLogo") }>
            <div className="footerLogo">
              <div className="footerLogoWrapper">
                <LazyloadImage
                  width={40}
                  height={50}
                  imgSrc={PostcodeAPIBlue}
                  alt="Postcode API"
                />
              </div>

            </div>
            <div className="footerAddress">
              <h3 className="footerColumnTitle">Postcode API</h3>
              <a href={ `mailto:info@postcodeapi.nu` } className="footerLink">info@postcodeapi.nu</a><br />
              <Link to="/algemene-voorwaarden" rel="nofollow noreferrer" className="footerLink">Algemene voorwaarden</Link><br />
              <Link to="/privacyverklaring" rel="nofollow noreferrer" className="footerLink">Privacyverklaring</Link>
            </div>
          </div>
          <nav className={ cx("footerColumn","footerLinks") }>
            <h3 className="footerColumnTitle">Product</h3>
            <Link to="/prijzen" className="footerLink">Prijzen</Link><br />
            <Link to="/account" className="footerLink">Account</Link><br />
            <Link to="/gratis-proberen" className="footerLink">Gratis proberen</Link><br />
            {/* <Link to="/veelgestelde-vragen" className="footerLink">FAQ</Link><br /> */}
          </nav>
          <div className={ cx("footerColumn") }>
            <h3 className="footerColumnTitle">Developers</h3>
            <Link to="/docs" className="footerLink">Documentatie</Link><br />
            <Link to="/sandbox" className="footerLink">Sandbox</Link><br />
            <a href="http://status.postcodeapi.nu" target="_blank" rel="nofollow noreferrer" className={"footerLink"}>API status</a><br />
          </div>
          <div className={ cx("footerColumn","footerPoweredBy") }>
            <div className="footerPoweredByContainer">
              <LazyloadImage
                imgSrc={AWSImage}
                srcSet={`${AWSImage} 1x, ${AWSx2Image} 2x`}
                alt="Powered by Amazon Web Services"
                width={150}
                height={54}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  </footer>
);

export default Footer;
