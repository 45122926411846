import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

const DefaultHeadMeta = (props) => (
  <div hidden>
    <Helmet
      meta={[
        {
          name: 'generator', content: `${
          process.env.PHENOMIC_NAME } ${ process.env.PHENOMIC_VERSION }`,
        },
        { property: 'og:site_name', content: 'Postcode API' },
        { name: 'twitter:site', content: `@postcodeapi` },
        { name: 'twitter:creator', content: `@postcodeapi` },
        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:image", content: "https://www.postcodeapi.nu/assets/share-image.png" },
        { name: 'theme-color', content: '#ffffff' },
        ...props.meta ? props.meta : [],
      ]}
      script={[
        { src: 'https://www.google-analytics.com/analytics.js', async: true },
        { src: '/assets/js/autotrack.custom.min.js', async: true },
        { src: 'https://polyfill.io/v3/polyfill.min.js?flags=gated%7Calways&features=default%2Ces2015%2Ces2016%2Ces2017%2Ces5%2Ces6%2Ces7%2CNodeList.prototype.forEach' },
        ...props.scripts ? props.scripts : [],
      ]}
      link={[
        { rel: 'apple-touch-icon', sizes:'180x180', href: '/assets/favicon/apple-touch-icon.png' },
        { rel: 'icon', type: 'image/png', sizes:'32x32', href: '/assets/favicon/favicon-32x32.png' },
        { rel: 'icon', type: 'image/png', sizes:'16x16', href: '/assets/favicon/favicon-16x16.png' },
        { rel: 'manifest', href: '/assets/favicon/manifest.json' },
        { rel: 'mask-icon', href: '/assets/favicon/safari-pinned-tab.svg', color: '#5bbad5' },
      ]}
    />

    { /* meta viewport safari/chrome/edge */ }
    <Helmet
      meta={ [ {
        name: 'viewport', content: 'width=device-width, initial-scale=1',
      } ] }
    />
    <style>{ '@-ms-viewport { width: device-width; }' }</style>
  </div>
)

DefaultHeadMeta.propTypes = {
  meta: PropTypes.arrayOf(PropTypes.object),
  scripts: PropTypes.arrayOf(PropTypes.object),
}

export default DefaultHeadMeta
