import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Loading from "./components/Loading";
import AppContainer from "./AppContainer";
import 'lazysizes';
import { signupCompletedHtml } from "./content/signup/completed";
import { sandboxFailedHtml } from "./content/sandbox/failed";
import { sandboxCompletedHtml } from "./content/sandbox/completed";
import { signUpFailedHtml } from "./content/signup/failed";
import Pages from "./global/pages";
import PluginPage from "./components/PluginPage";
import SDKPage from "./components/SDKPage";
import ScrollToTop from "./components/ScrollToTop";

const Homepage = lazy(() => import("./pages/Homepage"));
const Account = lazy(() => import("./pages/Account"));
const Prices = lazy(() => import("./pages/Prices"));
const Documentation = lazy(() => import("./pages/Documentation"));
const Plugins = lazy(() => import("./pages/Plugins"));
const Free = lazy(() => import("./pages/Free"));
const Post = lazy(() => import("./pages/Post"));
const Sandbox = lazy(() => import("./pages/Sandbox"));
const TermsAndConditions = lazy(() => import("./pages/TermsAndConditions"));
const PrivacyStatement = lazy(() => import("./pages/PrivacyStatement"));
const PageError = lazy(() => import("./pages/PageError"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const AccountCompleted = lazy(() => import("./pages/Account/completed"));
const DashboardCompleted = lazy(() => import("./pages/Dashboard/completed"));
const PaymentCompleted = lazy(() => import("./pages/Payment/completed"));
const PostcodeDatabase = lazy(() => import("./pages/PostcodeDatabase"));
const PostcodeTabel = lazy(() => import("./pages/PostcodeTabel"));
const Signup = lazy(() => import("./pages/Signup"));
const Payment = lazy(() => import("./pages/Payment"));

function App() {
  return (
    <Router>
      <AppContainer>
        <ScrollToTop />
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path="/account">
              <Account head={{
                title: 'Account'
              }} />
            </Route>
            <Route exact path="/payment">
              <Payment head={{
                title: 'Payment'
              }} />
            </Route>
            <Route path="/payment/completed">
              <PaymentCompleted />
            </Route>
            <Route exact path="/account/dashboard">
              <Dashboard head={{
                title: 'Account'
              }} />
            </Route>
            <Route path="/account/dashboard/completed">
              <DashboardCompleted />
            </Route>
            <Route path="/account/completed">
              <AccountCompleted />
            </Route>
            <Route path="/prijzen">
              <Prices head={{
                title: 'Prijzen'
              }} />
            </Route>
            <Route path="/registreren">
              <Signup head={{
                title: "Registreren"
              }} />
            </Route>
            <Route path={Pages.plugins}>
              <Plugins head={{
                title: 'Implementatie'
              }} />
            </Route>
            <Route path="/gratis-proberen">
              <Free head={{
                title: 'Gratis proberen'
              }} />
            </Route>
            <Route path="/signup/completed">
              <Post
                htmlString={signupCompletedHtml}
                head={{
                  title: 'Je bent klaar!'
                }}
              />
            </Route>
            <Route path="/sandbox/failed">
              <Post
                htmlString={sandboxFailedHtml}
                head={{
                  title: 'Er is iets misgegaan...'
                }}
              />
            </Route>
            <Route path="/sandbox/completed">
              <Post
                htmlString={sandboxCompletedHtml}
                head={{
                  title: 'Welkom bij Postcode API '
                }}
              />
            </Route>
            <Route exact path="/sandbox">
              <Sandbox head={{
                title: 'Sandbox'
              }} />
            </Route>
            <Route path="/account/dashboard/failed">
              <Post
                htmlString={signUpFailedHtml}
                head={{
                  title: 'Er is iets misgegaan...'
                }}
              />
            </Route>
            <Route path="/signup/failed">
              <Post
                htmlString={signUpFailedHtml}
                head={{
                  title: 'Er is iets misgegaan...'
                }}
              />
            </Route>
            <Route path="/docs">
              <Documentation head={{
                title: 'Documentatie'
              }} />
            </Route>
            <Route path="/algemene-voorwaarden">
              <TermsAndConditions />
            </Route>
            <Route path="/privacyverklaring">
              <PrivacyStatement />
            </Route>
            <Route path="/privacyverklaring">
              <PrivacyStatement />
            </Route>
            {/*seo routes*/}
            <Route path="/cnet-postcodeapi">
              <SDKPage
                name=".NET"
                sdkNames={['.NET']}
              />
            </Route>
            <Route path="/nodejs-postcodeapi">
              <SDKPage
                name="NodeJs"
                sdkNames={['NodeJs']}
              />
            </Route>
            <Route path="/php-postcodeapi">
              <SDKPage
                name="PHP"
                sdkNames={['PHP']}

              />
            </Route>
            <Route path="/symfony-postcodeapi">
              <SDKPage
                name="Symfony"
                sdkNames={['Symfony']}
              />
            </Route>
            <Route path="/python-postcodeapi">
              <SDKPage
                name="Python"
                sdkNames={['Python']}
              />
            </Route>
            <Route path="/ruby-postcodeapi">
              <SDKPage
                name="Ruby"
                sdkNames={['Ruby']}
              />
            </Route>
            <Route path="/postcodedatabase" >
              <PostcodeDatabase />
            </Route>
            <Route path="/postcodetabel" >
              <PostcodeTabel />
            </Route>
            <Route path="/magento" >
              <PluginPage
                name="Magento"
                version="2.0+"
                description="Magento 2"
                link="https://www.postcode-checkout.nl/index.php?core[page]=186&postcode-orders-papi[view]=step1"
              />
            </Route>
            <Route path="/opencart">
              <PluginPage
                name="Open Cart"
                version="3.0 - 3.0.2"
                description="OpenCart 3"
                link="https://www.postcode-checkout.nl/index.php?core[page]=186&postcode-orders-papi[view]=step1"
              />
            </Route>
            <Route path="/woo-commerce">
              <PluginPage
                name="WooCommerce"
                version="3.0 - 5.0.0"
                description="WooCommerce"
                link="https://www.postcode-checkout.nl/index.php?core[page]=186&postcode-orders-papi[view]=step1"
              />
            </Route>
            <Route path="/wordpress">
              <PluginPage
                name="WordPress"
                version="Contact Form 7"
                isWebShop={false}
                description="WordPress"
                link="https://www.postcode-checkout.nl/index.php?core[page]=186&postcode-orders-papi[view]=step1"
              />
            </Route>
            <Route path="/prestashop">
              <PluginPage
                name="Prestashop"
                version="1.7.0.0 - 1.7.7.0"
                description="Prestashop 1.7"
                link="https://www.postcode-checkout.nl/index.php?core[page]=186&postcode-orders-papi[view]=step1"
              />
            </Route>

            <Route exact path="/">
              <Homepage
                head={{
                  title: 'Postcode API - De standaard in adresvalidatie',
                  description: 'Nederlandse adressen en postbussen beschikbaar via een API. 100% nauwkeurig, direct beschikbaar en eenvoudig te implementeren.'
                }}
              />
            </Route>

            <Route path="/*">
              <PageError />
            </Route>
          </Switch>
        </Suspense>
      </AppContainer>
    </Router>
  );
}

export default App;
