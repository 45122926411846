import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNameFactory from "classnames";
import Container from '../Container';

import PostcodeAPISVG from '../../assets/postcode-api.svg';
import BgImage from '../../assets/bg.svg';
import Pages from "../../global/pages";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lastScrollPoint: 0,
      logoWidth: 15,
      logoHeight: 19,
      labelOpen: false
    };
    this._handleLabelClick = this._handleLabelClick.bind(this);
    this._handleLinkClick = this._handleLinkClick.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.onWindowResize, true);
    this.onWindowResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize, true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setClassToBody();
    }
  }

  setClassToBody() {
    if (window.innerWidth <= 992 && this._staticHeader()) {
      document.body.classList.add('mobile-header');

      return;
    }

    document.body.classList.remove('mobile-header');
  }

  onWindowResize= () => {
    this.setClassToBody();

    if (window.innerWidth > 992) {
      this.setState({
        labelOpen: false,
        logoWidth: 60,
        logoHeight: 76
      });
    } else {
      this.setState({
        logoWidth: 15,
        logoHeight: 19
      });
    }
  };

  _handleLabelClick() {
    this.setState({
      labelOpen: !this.state.labelOpen
    });
  }

  _handleLinkClick() {
    this.setState({
      labelOpen: false
    });
  }

  _staticHeader() {
    return this.props.location.pathname !== '/' && !this.props.location.pathname.includes('prijzen');
  }

  render() {
    const headerClassName = classNameFactory({
      "header": true,
      "headerStatic": this._staticHeader(),
      "headerFixed": this.state.labelOpen
    });

    const labelIconClassName = classNameFactory("headerLabelToggleIcon", {
        "headerLabelToggleIconOpen": this.state.labelOpen
    });

    return (
      <header className={ headerClassName }>
        <Container>
            { this.props.location.pathname === '/account/dashboard/' &&
              <nav className="headerNav">
                <div className="headerBrand">
                  <div className="headerBrandLink">
                    <img src={PostcodeAPISVG}
                         alt="Postcode API"
                         width={this.state.logoWidth}
                         height={this.state.logoHeight}
                    />
                      <span>Postcode API</span>
                  </div>
                </div>
              </nav>
            }
            { this.props.location.pathname !== '/account/dashboard/' &&
            <nav className="headerNav">
              <div className="headerBrand">
                <Link to={ '/' } className="headerBrandLink" onClick={this._handleLinkClick}>
                  <img
                    src={PostcodeAPISVG}
                    alt="Postcode API"
                    width={this.state.logoWidth}
                    height={this.state.logoHeight}
                  />
                  { this.props.location.pathname === '/' &&
                    <h1>Postcode API</h1>
                  }
                  { this.props.location.pathname !== '/' &&
                    <span>Postcode API</span>
                  }
                </Link>
              </div>
              <div>
                <label htmlFor="menu-toggler" className={ labelIconClassName } aria-hidden="true" aria-label="Menu" onClick={ this._handleLabelClick }>
                  <span/>
                  <span/>
                  <span/>
                  <span/>
                  <span/>
                  <span/>
                </label>
                <input id="menu-toggler" type="checkbox" className="headerMenuToggle" readOnly checked={ this.state.labelOpen }/>
                <div className="headerItems">
                  <Link to="/prijzen" className="headerLink" onClick={ this._handleLinkClick }>
                    Prijzen
                  </Link>
                  <Link to="/docs" className="headerLink" onClick={ this._handleLinkClick }>
                    Documentatie
                  </Link>
                  <Link to={Pages.plugins} className="headerLink" onClick={ this._handleLinkClick }>
                    Plugins
                  </Link>
                  {/* <Link to="/veelgestelde-vragen" className="headerLink" onClick={ this._handleLinkClick }>
                    FAQ
                  </Link> */}
                  <Link to="/account" className={ classNameFactory("headerLink","headerLinkButton", "button", "buttonOutline") } onClick={this._handleLinkClick}>
                    Account
                  </Link>
                  <Link to="/gratis-proberen" className={ classNameFactory("headerLink","headerLinkButton", "button", "buttonCta") } onClick={ this._handleLinkClick }>
                    Gratis proberen
                  </Link>
                </div>
              </div>
            </nav>
            }
          { this._staticHeader() && <div className="headerBg" style={{
            backgroundImage: `url(${BgImage})`
          }}/>}
        </Container>
      </header>
    );
  }
}

Header.propTypes = {
  location: PropTypes.object.isRequired
};

export default Header;
