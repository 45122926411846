
export class PostcodeCheckout {
  static _plugins = [];

  static internalPluginLinkMapping = {
    opencart_3: '/opencart',
    magento_2: '/magento',
    prestashop_17: '/prestashop',
    wordpress_woocommerce: '/woo-commerce',
    wordpress_contactform_7: '/wordpress'
  }

  static async getPlugins() {
    if (PostcodeCheckout._plugins.length) {
      return PostcodeCheckout._plugins;
    }
    const res = await fetch('https://www.postcode-checkout.nl/version/index.php', {
      headers: {
        'Authorization': 'Bearer eyJwY2lkIjo2OTQyLCJjbGllbnQiOiJwb3N0Y29kZV9hcGkiLCJlbnYiOiJQIiwiZXhwIjo3MjU4MDI4NDAwfQ=='
      }
    })
    const plugins = await res.json();
    const pluginsToArray = Object.keys(plugins).reduce((total, key) => {
      if (key === 'extended') {
        return total;
      }

      return [...total, plugins[key]];
    }, []);

    PostcodeCheckout._plugins = pluginsToArray;

    return pluginsToArray;
  }

  static async getPlugin(plugin) {
    if (!PostcodeCheckout._plugins.length) {
      await PostcodeCheckout.getPlugins();
    }

    const code = Object.keys(PostcodeCheckout.internalPluginLinkMapping).find((key) => {
     return PostcodeCheckout.internalPluginLinkMapping[key] === `/${plugin}`;
    });

    if (!code) {
      return null;
    }

    return PostcodeCheckout._plugins.find(plugin => plugin.code === code);
  }
}
