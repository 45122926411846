import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Container = (props) => (
  <div className={ cx('container', { "containerCenter": props.alignment === 'center' }) }>
    { props.children }
  </div>
);

Container.propTypes = {
  children: PropTypes.node,
  alignment: PropTypes.string,
};

export default Container;
