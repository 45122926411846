import React from 'react';
import PropTypes from 'prop-types';

class Widget extends React.Component {
  componentDidMount() {
    window.postcodeapi = {
      token: process.env.REACT_APP_WIDGET_TOKEN,
      onError: this.props.onError || null,
      onSuccess: this.props.onSuccess || null,
      onLoading: this.props.onLoading || null,
    };
    if (navigator.userAgent !== "ReactSnap") {
      const s = document.createElement('script');
      s.src = `https://${process.env.REACT_APP_WIDGET_URL}/widget-v1.js?v=20171229`;
      s.async = true;
      s.defer = true;
      document.body.appendChild(s);
    }
  }

  render() {
    return (
      <fieldset>
        { this.props.children }
      </fieldset>
    );
  }
}

Widget.propTypes = {
  children: PropTypes.node,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  onLoading: PropTypes.func,
};

export default Widget;
