import React from 'react';
import PropTypes from 'prop-types';
import DefaultHeadMeta from './components/DefaultHeadMeta';
import Header from './components/Header';
import Content from './components/Content';
import Footer from './components/Footer';
import {
  useLocation
} from "react-router-dom";

import './scss/styles.scss';

if (typeof window !== 'undefined') {
  window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date();

  /* global ga: true */
  ga('create', process.env.REACT_APP_GOOGLE_ANALYTICS_UA, 'auto');
  ga('set', 'anonymizeIp', true);
  ga('require', 'urlChangeTracker');
  ga('require', 'outboundLinkTracker');
  ga('send', 'pageview');
}

const AppContainer = (props) => {
  const location = useLocation();
  return (
    <div>
      <DefaultHeadMeta />
      <Header location={ location } />
      <Content location={ location }>
        { props.children }
      </Content>
      { location.pathname !== '/account/dashboard/' &&
        <Footer />
      }
    </div>
  );
};

AppContainer.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object
};

export default AppContainer;
