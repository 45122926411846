import React from 'react';
import {Link} from "react-router-dom";

const SDKTable = ({sdks, hideLinks}) => {
  return (
    <div className="tableContainer tableContainer--sdks">

      <table className="table">
        <tbody>
        <tr>
          <th>Programmeertaal</th>
          <th>Specs</th>
          <th>Door</th>
            <th>Github</th>
        </tr>
        {sdks.map((sdk, i) => (
          <tr key={i}>
            <td>
              <Link to={sdk.internalLink}>
                <img src={sdk.logo} alt={sdk.by}/>
              </Link>
            </td>
            <td>
              {!hideLinks ? (
                <Link to={sdk.internalLink}>{sdk.spec}</Link>

              ) : (
                sdk.spec
              )}
            </td>
            <td>{sdk.by}</td>
              <td>
                <a href={sdk.link} target="_blank" rel="nofollow noreferrer noopener">Naar Github</a>
              </td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  )
}

export default SDKTable;
