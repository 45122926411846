import React, {useMemo} from 'react';
import classnames from "classnames";

const LazyloadImage = (
  {
    width,
    height,
    maxHeight,
    className,
    imgSrc,
    srcSet,
    alt,
    imgClassName
  }) => {
  const ratio = useMemo(() => {
    return height/width;
  }, [width, height]);

  const styles = useMemo(() => {
    return {
      div: {
        position: 'relative',
        maxWidth: maxHeight ? `${maxHeight / ratio}px`  : '100%',
        paddingBottom: `${ratio*100}%`,
        margin: '0 auto'
      },
      img: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
      }
    }
  }, [ratio, maxHeight]);
  return (
    <div className={className} style={styles.div}>
      <img
        style={styles.img}
        alt={alt}
        data-src={imgSrc}
        data-src-set={srcSet}
        className={classnames('lazyload', imgClassName)}
      />
    </div>
  )
}
export default LazyloadImage;
