import React, { Component } from 'react';
import PropTypes from 'prop-types';
import hljs from 'highlight.js';

class BodyContainer extends Component {
  componentDidMount() {
    // Syntax highlighting
    const preNodes = this.node.querySelectorAll('pre code');
    preNodes.forEach(node => {
      hljs.highlightBlock(node);
    });

    // Reponsive Iframes
    const iframeNodes = this.node.querySelectorAll('iframe');
    const bodyWidth = this.node.offsetWidth;
    iframeNodes.forEach(node => {
      const ratio = node.height / node.width;
      node.width = bodyWidth;
      node.height = bodyWidth * ratio;
    });
  }

  render() {
    return (
      <div ref={ node => this.node = node } className="body">
        {/* body container? */}
        <div>
          { this.props.children }
        </div>
      </div>
    );
  }
}

BodyContainer.propTypes = {
  children: PropTypes.node,
};

export default BodyContainer;
